// AppContext.tsx
import React, { createContext, useContext, useReducer, Dispatch, useState, ReactNode, useEffect } from 'react';
import { Leaderboard } from '../interfaces/leaderboard';

interface HeaderContent {
  element: React.ReactNode;
}

interface FooterContent {
  element: React.ReactNode;
}
type State = {
  leaderboardState: Leaderboard[] | null;
  hasToken: boolean;
};

type Action = 
  | { type: 'SET_LEADERBOARD_STATE'; payload: Leaderboard[] }
  | { type: 'SET_TOKEN'; payload: boolean };

const initialState: State = {
  leaderboardState: null,
  hasToken: false
};

const AppContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
  headerContent: HeaderContent | null;
  setHeaderContent: React.Dispatch<React.SetStateAction<HeaderContent | null>>;  
  footerContent: FooterContent | null;
  setFooterContent: React.Dispatch<React.SetStateAction<HeaderContent | null>>;
}>({
  state: initialState,
  dispatch: () => null,
  headerContent: null,
  setHeaderContent: () => null,
  footerContent: null,
  setFooterContent: () => null,
});

const appReducer = (state: State, action: Action): State => {
  console.log('Reducer called with action:', action, action.type);
  console.log('Current state:', state);

  switch (action.type) {
    case 'SET_LEADERBOARD_STATE':
      return { ...state, leaderboardState: action.payload };
    case 'SET_TOKEN':
        return { ...state, hasToken: action.payload };
    default:
      return state;
  }
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [headerContent, setHeaderContent] = useState<HeaderContent | null>(null);

  const [footerContent, setFooterContent] = useState<FooterContent | null>(null);

  return (
    <AppContext.Provider value={{ state, dispatch, headerContent, setHeaderContent, footerContent, setFooterContent }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
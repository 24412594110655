// PipeService.ts

class CommonService {
    private static instance: CommonService;
  
    private constructor() {}
  
    public static getInstance(): CommonService {
      if (!CommonService.instance) {
        CommonService.instance = new CommonService();
      }
      return CommonService.instance;
    }
  
    generatePushID = (() => {
        // Modeled after base64 web-safe chars, but ordered by ASCII.
        var PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
      
        // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
        var lastPushTime = 0;
      
        // We generate 72-bits of randomness which get turned into 12 characters and appended to the
        // timestamp to prevent collisions with other clients.  We store the last characters we
        // generated because in the event of a collision, we'll use those same characters except
        // "incremented" by one.
        var lastRandChars: any = [];
      
        return function() {
          var now = new Date().getTime();
          var duplicateTime = (now === lastPushTime);
          lastPushTime = now;
      
          var timeStampChars = new Array(8);
          for (var i = 7; i >= 0; i--) {
            timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
            // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
            now = Math.floor(now / 64);
          }
          if (now !== 0) throw new Error('We should have converted the entire timestamp.');
      
          var id = timeStampChars.join('');
      
          if (!duplicateTime) {
            for (i = 0; i < 12; i++) {
              lastRandChars[i] = Math.floor(Math.random() * 64);
            }
          } else {
            // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
            for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
              lastRandChars[i] = 0;
            }
            lastRandChars[i]++;
          }
          for (i = 0; i < 12; i++) {
            id += PUSH_CHARS.charAt(lastRandChars[i]);
          }
          if(id.length !== 20) throw new Error('Length should be 20.');
      
          return id;
        };
      })();
  
      wrapText(context: { measureText: (arg0: string) => any; strokeText: (arg0: string, arg1: any, arg2: any) => void; fillText: (arg0: string, arg1: any, arg2: any) => void; }, text: string, x: number, y: any, maxWidth: number, lineHeight: any) {
        var words = text.split(" ");
        var line = "";
      
        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + " ";
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.strokeText(line, x + 10, y);
            context.fillText(line, x + 10, y);
            line = words[n] + " ";
            y += lineHeight;
          } else {
            line = testLine;
          }
        }
        context.strokeText(line, x + 10, y);
        context.fillText(line, x + 10, y);
      }
  
      capitalize(input: string) {  
        var words = input.split(' ');  
        var CapitalizedWords: string[] = [];  
        words.forEach(element => {  
            if (element[0]) {
              CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));  
            }
        });  
        return CapitalizedWords.join(' ');  
      }  
  
      base64MimeType(encoded: string) {
        var result = null;
      
        if (typeof encoded !== 'string') {
          return result;
        }
      
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
      
        if (mime && mime.length) {
          result = mime[1];
        }
      
        return result;
      }
  
      arrayToChunks (arr:[], len: number) {
  
        const chunks = [],
        n = arr.length;
        let   i = 0;
  
        while (i < n) {
          chunks.push(arr.slice(i, i += len));
        }
  
        return chunks;
      }
  
      getYoutubeId = (url: string) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length===11)? match[7] : false;
      }
  
      getYoutubePoster = (url: string) => {
        let id = this.getYoutubeId(url)
        return `https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`
      }
  
      parseImageKitUrl = (url: string, opts: { width: string; height: string; } | undefined) => {
        try {
          if (!url) return ""
          if (url.indexOf('activ8-b8409.appspot.com') === -1) return url;
          
          let pieces = url.split('/')
          let bits
          if (url.indexOf('%2F') > -1) {
            let piece = decodeURIComponent(pieces[pieces.length - 1]);
            bits = piece.split('/')
          } else {
            bits = [pieces[pieces.length - 2], pieces[pieces.length -1]]
          }
          return this.getImageKitUrl(bits[0], bits[1], opts);
        } catch (e) {
  
        }
  
      }
  
      getImageKitUrl = (type: string, file: string | string[], opts?: { width: string; height: string; }) => {
        if (opts) {
          let params = ''
          if (opts.width) {
            params += 'w-' + opts.width + ','
          }
  
          if (opts.height) {
            params += 'h-' + opts.height + ','
          }
  
          params = params.slice(0, -1)
  
          return `https://ik.imagekit.io/battlefit/${type}/tr:di-video-poster_xQuz8_i1t.png,${params}/${file}`
        }
        
        if (type === 'avatars') {
          return `https://ik.imagekit.io/battlefit/${type}/tr:di-default-profile_03ewOi9XW.jpg,n-avatar/${file}`
        }
  
        if (type === 'timelapse' && file.indexOf('.jpg') > -1) {
          return `https://ik.imagekit.io/battlefit/${type}/tr:di-video-poster_xQuz8_i1t.png/${file}`
        }
  
        return `https://battlefit.b-cdn.net/${type}/${file}`
        
      }
  
  
  
    formatDate = (inputDate: { getDate: () => any; getMonth: () => number; getFullYear: () => any; }) => {
      let date, month, year;
  
      date = inputDate.getDate();
      month = inputDate.getMonth() + 1;
      year = inputDate.getFullYear();
  
        date = date
            .toString()
            .padStart(2, '0');
  
        month = month
            .toString()
            .padStart(2, '0');
  
      return `${date}/${month}/${year}`;
    }
  
    convertStringToRgb = (str: any) => {
      function hashCode(str: string) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
      } 
    
      function intToRGB(i: number) {
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return "00000".substring(0, 6 - c.length) + c;
      }
  
      return intToRGB(hashCode(str))
  
    
    }
  
    // getParamValueQueryString( paramName: any, defaultValue?: any ) {
    //   const url = window.location.href;
    //   let paramValue;
    //   if (url.includes('?')) {
    //     const httpParams = new HttpParams({ fromString: url.split('?')[1] });
    //     paramValue = httpParams.get(paramName);
  
    //     if (paramValue === 'false') {
    //       return false;
    //     } else if (paramValue === 'true') {
    //       return true;
    //     }
  
    //     if (paramValue === null && defaultValue) {
    //       return defaultValue
    //     }
    //   } else {
    //     if (defaultValue) {
    //       paramValue = defaultValue
    //     }
    //   }
      
    //   return paramValue;
    // }
  
    getTimeZone() {
      var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
      return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    }
  
    generateRandomNumber(min: number, max: number) {
      console.log(min,max)
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  
    dateToLocalISO(date: { getTimezoneOffset: () => any; getTime: () => number; }) {
      const off    = date.getTimezoneOffset()
      const absoff = Math.abs(off)
      return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
              (off > 0 ? '-' : '+') + 
              Math.floor(absoff / 60).toFixed(0).padStart(2,'0') + ':' + 
              (absoff % 60).toString().padStart(2,'0'))
    }
  
    slugify = (str: string) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  
    getTotalArrayLengthFromObj(obj: { [x: string]: string | any[]; }) {
      let total = 0;
      for (let key in obj) {
        total += obj[key].length;
      }
      return total;
    }
  
    timeStrToMs(timeStr: { split: (arg0: string) => { (): any; new(): any; map: { (arg0: NumberConstructor): [any, any, any]; new(): any; }; }; }) {
      let [hours, minutes, seconds] = timeStr.split(':').map(Number);
      let totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
      return totalSeconds * 1000;
    }
  
    msToTimeStr(duration: number) {
      let hours = Math.floor(duration / (1000 * 60 * 60));
      let minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((duration % (1000 * 60)) / 1000);
    
      let timeStr = "";
      if (hours > 0) {
        timeStr += hours.toString().padStart(2, "0") + ":";
      }
      timeStr += minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    
      return timeStr;
    }
  
    truncate(str: string, maxLength: number, showEllipsis = true): string {
      if (str.length > maxLength) {
        if (showEllipsis) {
          return str.substring(0, maxLength) + "...";
        }
        return str.substring(0, maxLength);
      } else {
        return str;
      }
    }
    // Add more methods as needed...
  }
  
  export default CommonService;
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppContext } from './state/state';
import { ApiService } from './services/ApiService';
import Home from './pages/Home';
import Share from './pages/Share';
import Leaderboard from './pages/Leaderboard';
import Workout from './pages/Workout';
import WorkoutLog from './pages/WorkoutLog';
import Layout from './layouts/main';
import LeaderboardCreate from './pages/LeaderboardCreate';
import LeaderboardsList from './pages/LeaderboardsList';

function AppRoutes() {
  const { dispatch, state } = useAppContext();
  const apiService = ApiService.getInstance();

  useEffect(() => {

    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'AUTH_TOKEN') {
        apiService.setToken(event.data.token);
        console.log('SET_TOKEN', event.data.token, !!event.data.token);
        dispatch({ type: 'SET_TOKEN', payload: !!event.data.token });

        // setTimeout(() => {

        // }, 1000)
      }
    }

    console.log('Adding event listener for message');
    window.parent.postMessage({ type: 'PORTAL_READY'}, '*');
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [dispatch, apiService]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/share" element={<Share />} />
        <Route path="/leaderboards" element={<LeaderboardsList />} />
        <Route path="/leaderboard/create" element={<LeaderboardCreate />} />
        <Route path="/leaderboard/:type?/:id?" element={<Leaderboard />} />
        <Route path="/workout/:id?" element={<Workout />} />
        <Route path="/workout-log/:id" element={<WorkoutLog />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Share from './pages/Share';
import Leaderboard from './pages/Leaderboard';
import Workout from './pages/Workout';
import WorkoutLog from './pages/WorkoutLog';
import Layout from './layouts/main';
import { ApiService } from './services/ApiService';
import { AppProvider, useAppContext } from './state/state';
import LeaderboardCreate from './pages/LeaderboardCreate';
import LeaderboardsList from './pages/LeaderboardsList';
import AppRoutes from './AppRoutes';

function App() {
  return (

    <AppProvider>
    <Router>
        <AppRoutes />
    </Router>
    </AppProvider>
  );
}

export default App;
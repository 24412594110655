
export const environment = {
    production: false,
    version: '1.0.0',
    firebase: {
      apiKey: 'AIzaSyBHDjahv-N9-0TMfix3YxMWNwg2i0-FyKs',
      authDomain: 'activ8-b8409.firebaseapp.com',
      databaseURL: 'https://activ8-b8409.firebaseio.com',
      projectId: 'activ8-b8409',
      storageBucket: 'activ8-b8409.appspot.com',
      messagingSenderId: '955896974982',
      appId: '1:955896974982:web:8429b8cea2833893cf0b07',
      measurementId: 'G-CTK5FHJLV4'
    },
    API_URL: 'https://api.battlefitapp.com',    
  };
  
import { useState, useEffect } from 'react';
import { LeaderboardService } from '../services/LeaderboardService';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';

import './LeaderboardsList.scss';
import { exit } from 'process';
import Header from '../layouts/header';
import { useAppContext } from '../state/state';
import { useNavigate } from 'react-router-dom';
import { BookmarkAdd, PlaylistAdd } from '@mui/icons-material';
import { ListSubheader } from '@mui/material';
import { ApiService } from '../services/ApiService';

const LeaderboardsList = () => {
    const navigate = useNavigate();
    const leaderboardService = LeaderboardService.getInstance();
    const { state, dispatch, setHeaderContent} = useAppContext();
    const [leaderboards, setLeaderboards] = useState([]);
    const [globalLeaderboards, setGlobalLeaderboards] = useState([]);
    const selectList = (list: string) => {
        navigate(`/leaderboard/${list}`);
    }

    const getLeaderboards = async () => {
        console.log('gettttingt')
        
    };

    const exit = () => {
        window.parent.postMessage({ type: 'CLOSE'}, '*');
    }

    const create = () => {
        navigate('/leaderboard/create');
    }

    useEffect(() => {
        setHeaderContent({ element: 
            <Header title={'Leaderboards'} actions={{
                start: <div onClick={exit}><ArrowBack/></div>,
                end: <div onClick={create}><PlaylistAdd/></div>,
            }}>
               
            </Header>
        });
        return () => setHeaderContent(null); // Clean up when component unmounts
      }, []);

      useEffect(() => {
        console.log(state);
        if (state.hasToken) {
          console.log('getting leaderboards');
          
          const fetchLeaderboards = async () => {
            try {
              const leaderboards = await leaderboardService.list();
              setLeaderboards(leaderboards.filter((leaderboard: any) => !leaderboard.is_global));
              setGlobalLeaderboards(leaderboards.filter((leaderboard: any) => leaderboard.is_global));
            } catch (error) {
              console.error('Error fetching leaderboards:', error);
              // Handle error appropriately
            }
          };
      
          fetchLeaderboards();
        }
      }, [state.hasToken]);

    return (
        <>
        <List sx={{background:'#FFF'}}>
            <ListSubheader>Global</ListSubheader>
            {globalLeaderboards.map((leaderboard: any) => (
            <ListItemButton  sx={{borderBottom: '1px solid #f0f0f0'}}key={leaderboard.id} onClick={() => selectList(leaderboard.id)}>
                <ListItemText sx={{fontSize: '12px'}} primaryTypographyProps={{fontSize: '14px'}} primary={leaderboard.name} secondary={leaderboard.description} />
            </ListItemButton>
            ))}    
        </List>
        <List sx={{background:'#FFF'}}>
            <ListSubheader>Custom</ListSubheader>
            {leaderboards.map((leaderboard: any) => (
                <ListItemButton  sx={{borderBottom: '1px solid #f0f0f0'}}key={leaderboard.id} onClick={() => selectList(leaderboard.id)}>
                <ListItemText sx={{fontSize: '12px'}} primaryTypographyProps={{fontSize: '14px'}} primary={leaderboard.name} secondary={leaderboard.description} />
            </ListItemButton>
            ))}    
        </List>
            </>
    );
}
export default LeaderboardsList;
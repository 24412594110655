import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { TagsService } from '../services/TagsService';
import { Avatar, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField, Typography } from '@mui/material';
import PipeService from '../services/PipeService';
import TagIcon from '@mui/icons-material/Tag';
import classNames from 'classnames';
import zIndex from '@mui/material/styles/zIndex';
import { LeaderboardService } from '../services/LeaderboardService';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../state/state';
import Footer from '../layouts/footer';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.noOptions}`]: {
    display: 'none'
  },[`& .${autocompleteClasses.popper}`]: {
    position: 'absolute',
    width: '100%',
    zIndex: 10000
  },
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
    position: 'absolute',
    width: '100%',
    zIndex: 10000
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',

    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'center',
      gap: '10px',
      padding: 8,
      borderBottom: `1px solid  ${' #eaecef'}`,

      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
      ...theme.applyStyles('dark', {
        borderBottom: `1px solid  ${'#30363d'}`,
      }),
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledTagAvatar = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: '#000',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  ...theme.applyStyles('dark', {
    backgroundColor: '#fff',
    color: '#000',
  }),
}));

const StyledChips = styled('div')(({ theme }) => ({
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  gap: 10,
  width: '100%',
  overflowX: 'auto',
  [`.selected`]: {
    backgroundColor: 'var(--bf-color-primary)',
    color: '#FFF'
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}


const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${'#30363d'}`,
  position: 'relative',
  '& input': {
    borderRadius: 4,
    border: `1px solid ${'#30363d'}`,
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${'rgba(3, 102, 214, 0.3)'}`,
      borderColor: '#0366d6',
      ...theme.applyStyles('dark', {
        boxShadow: `0px 0px 0px 3px ${'rgb(12, 45, 107)'}`,
        borderColor: '#388bfd',
      }),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#fff',
      border: `1px solid ${'#eaecef'}`,
    }),
  },
  ...theme.applyStyles('dark', {
    borderBottom: `1px solid ${'#eaecef'}`,
  }),
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: 14,
  width: '100%',
  textAlign: 'left',
  padding: 10,
  color: '#fff',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: '#fff',
    ...theme.applyStyles('dark', {
      color: '#58a6ff',
    }),
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
  ...theme.applyStyles('dark', {
    color: '#8b949e',
  }),
}));

const filters = [
  {
    label: 'Tags',
    value: 'tag'
  },
  {
    label: 'Users',
    value: 'mention'
  },
  {
    label: 'Workouts',
    value: 'workout'
  },
  {
    label: 'Groups',
    value: 'group'
  },
  {
    label: 'Exercises',
    value: 'exercise'
  }

]

const labelNames:any = {
  'tag': 'Tags',
  'mention': 'Users',
  'workout': 'Workouts',
  'group': 'Groups',
  'exercise': 'Exercises'
}

export default function LeaderboardForm() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<{ [key: string]: LabelType[]}>({});
  const [tags, setTags] = React.useState<LabelType[]>([]);
  const [showTagOptions, setShowTagOptions] = React.useState<boolean>(false);
  const [selectedFilter, setFilter] = React.useState<any>(filters[0]);
  const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
  const [name, setName] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showNameDialog, setShowNameDialog] = React.useState<boolean>(false);
  const tagService = TagsService.getInstance();
  const leaderboardService = LeaderboardService.getInstance();
  const { avatar } = PipeService.getInstance();
  const { state, dispatch, setFooterContent} = useAppContext();


  const groupByProperty = <T extends Record<string, any>>(
    array: T[],
    property: keyof T
  ): Record<string, T[]> => {
    return array.reduce((acc, obj) => {
      const key = String(obj[property]);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {} as Record<string, T[]>);
  }

  const handleClose = () => {
    setValue(groupByProperty(pendingValue, 'type'));
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const selectFilter = (filter: any) => {
    setFilter(filter);
  }

  React.useEffect(() => { 

    setIsLoading(true);
    tagService.getAllTags(selectedFilter.value).then((tags) => {
      setTags(tags.map((tag) => ({  name: tag.value, color: '#000', description: tag.type, key: tag.key, type: tag.type, id: tag.id })));
      setIsLoading(false);
    });
    
  },[tagService, selectedFilter])

  const handleFocus = (event: any) => {
    setShowTagOptions(true);
  };

  const closeOptions = () => {
    setShowTagOptions(false);
    setValue(groupByProperty(pendingValue, 'type'));
  }

  const removeTag = (tag: LabelType) => {
    console.log(value, tag)
    const newTags = value[tag.type!].filter((t) => t.key !== tag.key);
    setValue({ 
      ...value, 
      [tag.type!]: newTags
    });

    setPendingValue(pendingValue.filter((t) => t.key !== tag.key));
  }

  const save = () => {
    setShowNameDialog(false);
    leaderboardService.create(pendingValue, 'last_24_hours', name);
    navigate('/leaderboards');
  }

  React.useEffect(() => {
    setFooterContent({ element: 
        <Footer>
          <Button className="default" onClick={() => setShowNameDialog(true)}>Save</Button>
        </Footer> 
    });
    return () => setFooterContent(null); // Clean up when component unmounts
  }, []);


  return (
    <React.Fragment>
      <Autocomplete
        open={showTagOptions}
        multiple
        onClose={(
          event: React.ChangeEvent<{}>,
          reason: AutocompleteCloseReason,
        ) => {
          if (reason === 'escape') {
            handleClose();
          }
        }}
        value={pendingValue}
        onChange={(event, newValue, reason) => {
          if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Backspace' ||
              (event as React.KeyboardEvent).key === 'Delete') &&
            reason === 'removeOption'
          ) {
            return;
          }
          setPendingValue(newValue);
        }}
        disableCloseOnSelect
        renderTags={() => null}
        noOptionsText={null}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          if (showTagOptions === false) { return null; }

          return (
            <li key={option.key} {...optionProps}>
              
              <Box
                component={DoneIcon}
                sx={{ width: 17, height: 17, mr: '0px', ml: '-2px' }}
                style={{
                  visibility: selected ? 'visible' : 'hidden',
                }}
              />
              
              {option.type === 'mention' && (
                <Avatar src={avatar(option.key.replace('mention_', ''))} />                      
              )}
              {option.type === 'group' && (
                <Avatar src={option.poster_url} />                      
              )}
              {option.type === 'tag' && (
                <StyledTagAvatar><TagIcon/></StyledTagAvatar>
              )}
              <Box
                sx={(t) => ({
                  flexGrow: 1,
                  '& span': {
                    color: '#8b949e',
                    ...t.applyStyles('light', {
                      color: '#586069',
                    }),
                  },
                })}
              >
                {option.name}
                <br />
                <span>{option.description}</span>
              </Box>
              <Box
                component={CloseIcon}
                sx={{ opacity: 0.6, width: 18, height: 18 }}
                style={{
                  visibility: selected ? 'visible' : 'hidden',
                }}
              />
            </li>
          );
        }}
        options={[...tags.filter((tag) => tag.type === selectedFilter.value)]}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <Box sx={{background:'#FFF'}}>
            <StyledInput
              onFocus={handleFocus} 
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              placeholder="Filter tags"
              onBlur={closeOptions}
            />


            {showTagOptions && (
              <Box sx={{ padding: '10px' }}>
            <StyledChips>
              { filters.map((filter) => <Chip className={classNames({ selected: selectedFilter.value === filter.value})} label={filter.label} size="small" onClick={() => selectFilter(filter) }/> )}
            </StyledChips>
            </Box>
            )}

            {isLoading && (
              <LinearProgress />
  )}

          </Box>
        )}
        slots={{
          popper: PopperComponent,
        }}
      />
      <Box>        
        {Object.keys(value).map((key) => (
          <>
          {value[key] && value[key].length > 0 && (
            <Box
              key={key}
              sx={{
                padding: '10px',
                backgroundColor: '#f6f8fa',
                borderBottom: '1px solid #eaecef',
              }}
            >
              <Typography component="div" sx={{marginBottom: '5px', fontSize: '14px'}}>
                {labelNames[key]}              
              </Typography> 

              <StyledChips>
                { value[key].map((filter) => <Chip label={filter.name} size="small" onDelete={() => removeTag(filter)}  /> )}
              </StyledChips>
            </Box>
          )}</>
        ))}
        
      </Box>
      
      <Dialog
        open={showNameDialog}
        onClose={handleClose}
        PaperProps={{
          component: 'form',         
        }}
      >
        {/* <DialogTitle>Save Leaderboard</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            Give this leaderboard a name.
          </DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Leaderboard name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNameDialog(false)}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

interface LabelType {
  id: any;
  name: string;
  color: string;
  description: string;
  type?: string;
  key: any
  value?: string
  label?: string
  poster_url?: string
}

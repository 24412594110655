import './footer.scss';


const Footer = ({ children}) => {

    return (
      <div className="footer">
         {children && <div className="header-content">{children}</div>}
      </div>
      
    );
  }
  

 export default Footer; 
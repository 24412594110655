import './header.scss';
import { useEffect, useState } from 'react';

const Header = ({ title, actions, children }) => {
    const [headerTitle, setHeaderTitle] = useState(title ?? 'Header');
    // const [actions, setActions] = useState(actions_ ?? {});

    useEffect(() => {
      setHeaderTitle(title);
    }, [title])

    // useEffect(() => {
    //   setActions(actions);
    // }, [actions])

    return (
      <>
        <div className="header">
          {actions && actions.start && <div className="start">{actions.start}</div>}
          <div className="page-title">{headerTitle}</div>
          {actions && actions.end && <div className="end">{actions.end}</div>}
        </div>
        
        {children && <div className="header-content">{children}</div>}
      </>
    );
  }
  

 export default Header; 
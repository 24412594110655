import './ListProfileComponent.scss';
import Leader from '../components/Leader';
import './Leaders.scss'
import { useEffect } from 'react';

const LeadersComponent = ({ leaders }) => {
    useEffect(() => {
        console.log(leaders);
    }, [leaders])
    return (
        <div className='leaders-component'>
            {leaders.map((leader, index) => (
                <Leader key={leader.key} leader={leader} className={{ first: index === 1, second: index === 0, third: index === 2 }}></Leader>
            ))}
            
        </div>
    );
}
export default LeadersComponent;
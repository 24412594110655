import { ApiService } from './ApiService'; // Adjust the import path as needed

export class TagsService {
  private static instance: TagsService;
  private api: ApiService;

  private constructor() {
    this.api = ApiService.getInstance();
  }

  public static getInstance(): TagsService {
    if (!TagsService.instance) {
      TagsService.instance = new TagsService();
    }
    return TagsService.instance;
  }

  async getAllTags(type: string) {
    return await this.api.get<any[]>('tags', { type });
  }
}
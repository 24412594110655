import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LeaderboardService } from '../services/LeaderboardService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './Leader.scss';
import { Avatar } from '@mui/material';
import PipeService from '../services/PipeService';
import classNames from 'classnames';
const LeaderComponent = ({ leader, className }) => {
    const { avatar } = PipeService.getInstance();
    
    return (
        <div className={classNames(['leader', className])}>
             <img src={avatar(leader.userId)} className='avatar' />

             <div className='leader-info'>
                <Typography variant="p" className="name" component="div">
                    {leader.firstName} {leader.lastName.charAt(0)}.
                </Typography>
                <Typography className='score' variant="p" component="div">
                    {leader.formatted}
                </Typography>
            </div>
        </div>
    );
}
export default LeaderComponent;
import { Outlet } from 'react-router-dom';
import './main.scss';
import { AppProvider } from '../state/state';
import { useAppContext } from '../state/state';
import { useEffect } from 'react';

import { ThemeOptions } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#3f4cbd',
    },
    secondary: {
      main: '#d96d5e',
    },
  },
};
const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
    },
    // Add other customizations here
  });
const Layout = () => {
    const { headerContent, footerContent } = useAppContext();

    
    useEffect(() => {
        console.log(headerContent)

    }, [headerContent])
    return (
        <ThemeProvider theme={theme}>
      <div className="layout">
        {headerContent && <header>
          {headerContent.element}
        </header>}
        <main>
          <Outlet /> {/* This is where the child routes will be rendered */}
        </main>
        {footerContent && <footer>
          {/* Your footer content */}
          {footerContent.element}
        </footer>}
      </div>
      </ThemeProvider>
    );
  }
  

 export default Layout; 
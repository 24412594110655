import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LeaderboardService } from '../services/LeaderboardService';
import ListProfileComponent from '../components/ListProfileComponent';
import { useAppContext } from '../state/state';
import Leaders from '../components/Leaders';
import { Box } from '@mui/material';
import Header from '../layouts/header';
import * as React from 'react';
import { Tabs, Tab, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';


import './Leaderboard.scss';
import { ArrowBack } from '@mui/icons-material';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 'auto',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: '6px 16px',
    },
  }));
  
  const CenteredTabsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '15px 0'
  });

const Leaderboard = () => {
    const navigate = useNavigate();

    const [value, setValue] = React.useState('global');
    const [filters, setFilters] = React.useState({});
    const [podium, setPodium] = React.useState([]);
    const [isLoading, setIsloading] = React.useState(false);
    const [title, setTitle] = React.useState('Leaderboard');

    const leaderboardService = LeaderboardService.getInstance();
    const { state, dispatch, setHeaderContent} = useAppContext();

    const { type: paramType, id } = useParams();
    const type = paramType || 'points';

    const [leaderboard, setLeaderboard] = useState({});
    const [leaderboards, setLeaderboards] = useState([]);
    const [leaders, setLeaders] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    useEffect(() => {
        setIsloading(true);
        leaderboardService.get(type, { filter: value }).then((data) => {
            setTitle(data.name);

            const leaderboards = Object.values(data.leaderboards);
            
            if (leaderboards.length > 0) {
            setLeaderboards(leaderboards);
            const leaderboardData = leaderboards[0]
            
            // If there are less than 3 results
            if (leaderboardData.data.length <= 3) {
                setLeaders(leaderboardData.data);
                setLeaderboard({ ...leaderboardData, data: [] });
                setIsloading(false);
                return;
            }

            setLeaderboard({ ...leaderboardData, data: leaderboardData.data.slice(3) });

            setLeaders(leaderboardData.data.slice(0, 3));
        }   
        setIsloading(false);

        });
    }, [value, setLeaderboard, setLeaders, leaderboardService, type, filters]);

    useEffect(() => {
        const mapping = {
            0: 1,
            1: 0,
            2: 2
        }
        // sort leaders array index based on the mapping
        const podium = leaders.map((leader, index) => {
            return {
                ...leader,
                position: mapping[index]
            }
        });

        // sort leaders by position
        podium.sort((a, b) => a.position - b.position);
        setPodium(podium);
        
    
    }, [leaders])

    const exit = () => {
        navigate(-1);
    }

    const changeLeaderboard = (event, newValue) => {
        console.log(newValue);
        console.log(leaderboards)
        const leaderboardData = leaderboards.find(leaderboard => leaderboard.type === newValue);
        
        if (leaderboardData.data.length <= 3) {
            setLeaders(leaderboardData.data);

            setLeaderboard({ ...leaderboardData, data: [] });
            setIsloading(false);
            return;
        }
        setLeaderboard({ ...leaderboardData, data: leaderboardData.data.slice(3) });
        setLeaders(leaderboardData.data.slice(0, 3));
        setIsloading(false);
    }

    useEffect(() => {
        setHeaderContent({ element: 
            <Header title={title} actions={{
                start: <div onClick={exit}><ArrowBack/></div>,
                // end: <div onClick={presentLeaderboardList}><AppsIcon /></div>
            }}>
                {isLoading && <LinearProgress />}

                <CenteredTabsContainer>
                    <StyledTabs 
                        variant="scrollable"
                        scrollButtons={false}
                        TabIndicatorProps={{
                            sx: {
                            height: 4,
                            },
                        }}
                        indicatorColor="secondary" 
                        className='filter' 
                        onChange={handleChange}  
                        value={value} 
                        aria-label="basic tabs example"
                    >
                        <Tab className='tab' label="Global" value='global' />
                        <Tab className='tab' label="Friends" value='friends' />
                    </StyledTabs>
                </CenteredTabsContainer>
                <CenteredTabsContainer>
                    <StyledTabs 
                        variant="scrollable"
                        scrollButtons={false}
                        TabIndicatorProps={{
                            sx: {
                            height: 4,
                            },
                        }}
                        onChange={changeLeaderboard}  
                        indicatorColor="secondary" 
                        className='filter' 
                        value={leaderboard.type}
                        aria-label="basic tabs example"
                    >
                        {leaderboards && leaderboards.map((leaderboard) => <Tab className='tab' label={leaderboard.name} value={leaderboard.type} /> )}
                    </StyledTabs>
                </CenteredTabsContainer>
            </Header> 
        });
        return () => setHeaderContent(null); // Clean up when component unmounts
      }, [setHeaderContent, type, value, leaderboards, title, leaderboard.type]);

    return (
        <div>
            <Box >
                
                    <Box sx={{marginTop: '100px'}}>
                    <Leaders leaders={podium}></Leaders>
                {leaderboard && leaderboard.data && leaderboard.data.length > 0 && leaders && leaders.length > 0 && 

                    <Box sx={{ px: '10px' }}>
                        {leaderboard.data.map((profile, index) => (
                            <ListProfileComponent profile={profile} key={profile.key} position={index}></ListProfileComponent>
                        ))}
                    </Box>
                }        
                    </Box>
            </Box>
            
        </div>
    );
}
export default Leaderboard;
// PipeService.ts

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CommonService from '../services/CommonService';
dayjs.extend(relativeTime);


class PipeService {
    private static instance: PipeService;
    
    public static commonService = CommonService.getInstance();
  
    private constructor() {}
  
    public static getInstance(): PipeService {
      if (!PipeService.instance) {
        PipeService.instance = new PipeService();
      }
      return PipeService.instance;
    }
  
    capitalize(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
  
    formatDate(date: Date | string, format: string = 'MM/dd/yyyy'): string {
      // Implement date formatting logic here
      // You might want to use a library like date-fns for more complex formatting
      const d = new Date(date);
      return d.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      });
    }
  
    truncate(str: string, length: number): string {
      return str.length > length ? str.substring(0, length) + '...' : str;
    }

    formatWorkoutResult(type: string, result: any, expectedResultFormat?: any) {
        if (type === 'timed' || type === 'timed_longest') {
          return result//this.durationPipe.transform(result, true);
        } else if  ((type === 'emom' || type === 'tabata' || type === 'reps' || type === 'amrap' || type === 'most_weight' || type === 'longest_distance') && expectedResultFormat !== 'none') {
          return result + ( expectedResultFormat ? expectedResultFormat : '');
        } else if (type === 'standard' || expectedResultFormat === 'none') {
          return 'Done';
        }
    }

    avatar(value: any): any {
        if (value === 'battle-bot') {
          return 'assets/icons/icon-64x64.png';
        }
        return CommonService.getInstance().getImageKitUrl('avatars', value + '.png');
    }
  
    formatTimeLimit(value: any, measurement: any): String {
      if (measurement === 'minutes') {
        return Math.ceil((value / 60)) + 'MIN';
      } else {
        if (value > 60) {
          return Math.ceil((value / 60)) + 'MIN';
        }
        return value + 's';
      }
    }
  
    fromNow(value: string, ...args: unknown[]): unknown {

      return dayjs(value).fromNow();
    }

    imagekit(url: string, opts?: { width: string; height: string; } | undefined): unknown {
      
      return CommonService.getInstance().parseImageKitUrl(url, opts)
    }

    nl2br(str: string, is_xhtml?: any): string {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  
    }

    objectkeys(value: any): any {
      return Object.keys(value);
    }
    // Add more methods as needed...
  }
  
  export default PipeService;
import axios, { AxiosRequestConfig } from 'axios';
import { config } from '../environment/config';
import { Dispatch } from 'react';

type Action = { type: 'SET_TOKEN'; payload: boolean };

export class ApiService {
  private static instance: ApiService;
  private static readonly baseURL = config.API_URL;
  static idToken: string = '';
  private static dispatch: Dispatch<Action> | null = null;


  private constructor() {}

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }

    return ApiService.instance;
  }

  private getConfig(): AxiosRequestConfig {
    return {
      headers: {
        'Authorization': `Bearer ${ApiService.idToken}`
      }
    };
  }
  
  // allow querystring params
  public async get<T>(url: string, params?: any): Promise<T> {
    const response = await axios.get<T>(`${ApiService.baseURL}/${url}`, { params, headers: ApiService.instance.getConfig().headers });
    return response.data;
  }
  // public async get<T>(url: string): Promise<T> {
  //   const response = await axios.get<T>(`${ApiService.baseURL}/${url}`);
  //   return response.data;
  // }

  public async post<T>(url: string, data: any): Promise<T> {
    const response = await axios.post<T>(`${ApiService.baseURL}/${url}`, data, ApiService.instance.getConfig());
    return response.data;
  }

  public async put<T>(url: string, data: any): Promise<T> {
    const response = await axios.put<T>(`${ApiService.baseURL}/${url}`, data, ApiService.instance.getConfig());
    return response.data;
  }

  public async delete<T>(url: string, data: any): Promise<T> {
    const response = await axios.delete<T>(`${ApiService.baseURL}/${url}`, ApiService.instance.getConfig());
    return response.data;
  }

  public setToken(token: string) {    
    ApiService.idToken = token;
  }

  public static setDispatch(dispatch: Dispatch<Action>) {
    ApiService.dispatch = dispatch;
  }
  
}